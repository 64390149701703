<template>
  <div class="operating_container">
    <div class="crumbs operating_item">
      <el-breadcrumb separator-icon="ArrowRight">
        <el-breadcrumb-item :to="{ path: '/workbench' }">
          <i class="iconfont icon-zhuye"></i
        ></el-breadcrumb-item>
        <el-breadcrumb-item>日前交易</el-breadcrumb-item>
        <el-breadcrumb-item>运行日对比</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="operating_item flex_between">
      <div class="flex_start operation">
        <div class="flex_start">
          <span>运行日：</span>
          <el-date-picker
            v-model="operation"
            type="date"
            placeholder="请选择"
            @change="changeType($event, 1)"
            value-format="YYYY-MM-DD"
            :disabled-date="disabledDate"
            :clearable="false"
          />
        </div>
        <div class="flex_start">
          <span>对比日：</span>
          <el-date-picker
            v-model="comparison.data"
            type="dates"
            placeholder="请选择"
            @change="changeType($event, 2)"
            :disabled-date="disabledCon"
            value-format="YYYY-MM-DD"
          />
        </div>
        <div class="flex_start">
          <span>分析时点：</span>
          <el-select v-model="pointVal" class="select_day" placeholder="Select">
            <el-option
              v-for="item in pointList.data"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
    </div>

    <div class="main_container">
      <el-collapse
        v-model="activeName.data"
        @change="handleChange"
        class="category"
      >
        <el-collapse-item title="电量数据" :name="1">
          <el-tree
            :props="props"
            show-checkbox
            :data="typeList.power"
            @check="handleCheckChange($event, 1)"
            :default-checked-keys="['0-1', '0-2']"
            node-key="id"
            class="tree_collapse"
          />
        </el-collapse-item>

        <el-collapse-item title="电价数据" :name="2">
          <el-tree
            :props="props"
            show-checkbox
            :data="typeList.price"
            @check="handleCheckChange($event, 1)"
            class="tree_collapse"
          />
        </el-collapse-item>
        
        <el-collapse-item title="其他数据" :name="3">
          <el-tree
            :props="props"
            show-checkbox
            :data="typeList.other"
            @check="handleCheckChange($event, 1)"
            class="tree_collapse"
          />
        </el-collapse-item>
      </el-collapse>

      <div class="echarts_container">
        <div
          class="echarts_item"
          v-for="(item, index) in echartsList.data"
          :key="index"
        >
          <div class="echarts_box">
            <div class="chart">
              <div class="operating_title">{{ item.name }}</div>
              <div
                class="echarts_content"
                :id="item.targetId"
                :ref="item.targetId"
              ></div>
            </div>
            <div class="day_details">
              <el-radio-group v-model="item.tabs">
                <el-radio-button
                  :label="radio.value"
                  v-for="(radio, indexRadio) in valList.data"
                  :key="indexRadio"
                  >{{ radio.label }}</el-radio-button
                >
              </el-radio-group>

              <div class="day_title">运行日</div>
              <div class="flex_center">
                <div class="date_day">{{item.date}}</div>
                <div class="date_day_none">--</div>
              </div>

              <el-table
                header-row-class-name="table_double th"
                :row-class-name="tableRowClassName"
                :data="item.table"
                class="table_day"
              >
                <el-table-column
                  prop="date"
                  label="对比日期"
                  class="table_date"
                >
                </el-table-column>
                <el-table-column prop="num" label="" />
                <el-table-column prop="percentage" label="" />
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, nextTick, onMounted } from "vue";
export default {
  name: "operatingDay",
  setup() {
    const { proxy } = getCurrentInstance(); // proxy是组件实例化对象

    let pointVal = ref(1);
    let pointList = reactive({
      data: [
        { label: "全天", value: 1 },
        { label: "峰段", value: 2 },
        { label: "平段", value: 3 },
        { label: "谷段", value: 4 },
      ],
    });

    console.log(proxy);
    let operation = ref(proxy.$moment().format("YYYY-MM-DD"));
    console.log(operation);
    let comparison = reactive({ data: [] });

    let activeName = reactive({ data: [1, 2, 3] });

    let typeList = reactive({
      power: [],
      price: [],
      other: [],
    });

    let type1 = [
      "统调",
      "西电",
      "B类",
      "A类",
      "交易电量",
      "偏差",
      "地方电",
      "粤澳联络线",
      "检修",
      "必开",
      "必停",
      "正备用",
      "负备用",
    ];

    let type2 = [
      "统一结算点",
      "全省节点平均价格",
      "节点价格平均（系统计算）",
      "偏差",
    ];

    type1.forEach((item, index) => {
      typeList.power.push({
        name: item,
        id: index,
        children: [
          { name: "预测", id: index + "-1" },
          { name: "实际", id: index + "-2" },
        ],
      });
    });

    type2.forEach((item, index) => {
      let current = {
        name: item,
        id: index + 100,
        children: [
          { name: "目前价格", id: index + 100 + "-1" },
          { name: "实时价格", id: index + 100 + "-2" },
        ],
      };
      if (index == type2.length - 1) {
        current = {
          name: item,
          id: index + 100,
          children: [
            { name: "统一结算点偏差", id: index + 100 + "-1" },
            { name: "全省节点平均价格偏差", id: index + 100 + "-2" },
            { name: "节点价格平均偏差", id: index + 100 + "-3" },
          ],
        };
      }
      typeList.price.push(current);
    });

    let otherList = ["广东省温度", "西南降水", "需供比"];
    let citys = [
      "全省",
      "广州市",
      "深圳市",
      "清远市",
      "韶关市",
      "河源市",
      "梅州市",
      "潮州市",
      "汕头市",
      "揭阳市",
      "汕尾市",
      "惠州市",
      "东莞市",
      "珠海市",
      "中山市",
      "江门市",
      "佛山市",
      "肇庆市",
      "云浮市",
      "阳江市",
      "茂名市",
      "湛江市",
    ];

    otherList.forEach((item, index) => {
      let current = {
        name: item,
        id: "other-" + index,
        children: [],
      };

      if (index == 0) {
        citys.forEach((label, indexCity) => {
          current.children.push({ name: label, id: "city-" + indexCity });
        });
      } else if (index == 2) {
        current.children.push(
          { name: "预测", id: "provide-" + 1 },
          { name: "实际", id: "provide-" + 2 }
        );
      }
      typeList.other.push(current);
    });

    const valList = reactive({
      data: [
        { label: "均值", value: 1 },
        { label: "最大值", value: 2 },
        { label: "最小值", value: 3 },
      ],
    });

    let echartsList = reactive({ data: [] });

    let tableList = reactive({
      data: [
        { date: "2022-09-15", num: "112,662.674", percentage: "0%" },
        { date: "2022-09-15", num: "112,662.674", percentage: "0%" },
        { date: "2022-09-15", num: "112,662.674", percentage: "0%" },
      ],
    });
    const tableRowClassName = ({ row, rowIndex }) => {
      if (rowIndex % 2 == 0) {
        return "table_double";
      } else {
        return "table_single";
      }
    };

    let checkData = reactive({ data: ["0-1", "0-2"] });

    const handleCheckChange = (current, flag, type) => {
      if (current.children && current.children.length > 0) {
        let isCheck = [];
        current.children.forEach((val) => {
          let index = checkData.data.indexOf(val.id);
          if (index > -1) {
            isCheck.push(true);
          } else {
            isCheck.push(false);
          }
        });
        let isTrue = isCheck.every((each, index, arry) => {
          return each;
        });
        if (isTrue) {
          current.children.forEach((val) => {
            let index = checkData.data.indexOf(val.id);
            checkData.data.splice(index, 1);
          });
        } else {
          let copy = [].concat(current.children);
          copy.reverse();
          copy.forEach((val) => {
            let index = checkData.data.indexOf(val.id);
            if (index > -1) {
              checkData.data.splice(index, 1);
              checkData.data.unshift(val.id);
            } else {
              checkData.data.unshift(val.id);
            }
          });
        }
      } else {
        let index = checkData.data.indexOf(current.id);
        if (index > -1) {
          checkData.data.splice(index, 1);
        } else {
          checkData.data.unshift(current.id);
        }
      }

      chartFn();
    };

    let echatsMap = reactive({
      options: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          formatter: function (params) {
            console.log(params);
            let back = params[0].name;
            for (let i = 0, l = params.length; i < l; i++) {
              back +=
                "<br/>" +
                params[i].marker +
                params[i].seriesName +
                "  " +
                params[i].value +
                "%";
            }
            return back;
          },
        },
        dataZoom: [
          {
            type: "inside", //slider表示有滑动块的，inside表示内置的
          },
        ],
        legend: {
          type: "scroll",
          icon: "rectangle", //区域块
          // icon:'roundRect', //直线  itemHeight:2//粗细
          left: "4%",
          right: "4%",
          // x:'center',
          data: [],
          textStyle: {
            color: "#FFFFFF",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              textStyle: {
                color: "#FFFFFF",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位：元/WMh",
            nameTextStyle: {
              color: "#FFFFFF",
              align: "left",
            },
            axisLine: { onZero: false, show: true },
            axisLabel: {
              textStyle: {
                color: "#FFFFFF",
              },
              formatter: "{value} ",
            },
            splitLine: {
              show: true, //是否展示
              lineStyle: {
                color: "#8291A9", //线条颜色
                type: "dashed", //线条样式，默认是实现，dashed是虚线
              },
            },
          },
        ],
        series: [],
      },
    });

    const renderChart = (target, maps) => {
      // 渲染图表
      let chart = proxy.$echarts.init(document.getElementById(target));
      chart.setOption(maps,true);
      chart.group='group1'
      window.onresize = () => {
        chart.resize();
      };
    };

    const reback = (data, id, father) => {
      let result;
      if (!data) {
        return; //如果data传空，直接返回
      }
      for (let t = 0; t < data.length; t++) {
        if (data[t].id == id) {
          result = data[t];
          result.father = father;
          return result;
        } else {
          if (data[t].children && data[t].children.length > 0) {
            result = reback(data[t].children, id, data[t]);
            if (result) {
              return result;
            }
          }
        }
      }
      return result;
    };
    let titles = [
      "00:00",
      "01:15",
      "02:30",
      "03:45",
      "04:00",
      "05:10",
      "06:30",
      "07:45",
      "08:00",
      "09:15",
      "10:30",
      "11:45",
      "12:00",
      "13:15",
      "14:30",
      "15:45",
      "16:00",
      "17:15",
      "18:30",
      "19:45",
      "20:00",
      "21:15",
      "22:30",
      "23:45",
    ];



    const chartFn = async () => {
      let type = [];
      type = typeList.power.concat(typeList.price).concat(typeList.other);
      let arry = reactive({ data: [] });
     
      for (let i = 0; i < checkData.data.length; i++) {
        let current = reback(type, checkData.data[i], checkData.data[i]);
        let listdatas=[]
        echartsList.data.forEach(ele => {
          listdatas=ele.table
        });
        arry.data.push({
          tabs: 1,
          targetId: "echatrts-" + i,
          date:operation.value,
          table:listdatas,
          name: current.father.name + "-" + current.name,
        });
     
     
      }
      console.log(arry.data)

      echartsList.data = arry.data;
    
        let array = JSON.parse(JSON.stringify(echatsMap.options));
      await nextTick(() => {

      console.log(comparison.data)
       array.series=[]
        let dateAll = comparison.data&&comparison.data.length>0?[].concat(comparison.data):[];
        dateAll.unshift(operation.value);
        dateAll.forEach((date, indexDate) => {
          array.legend.data.push(date);
          array.series.push({
            type: "line",
            name: date,
            data: [],
            showSymbol: false,
            smooth: false,
            itemStyle: {
              normal: {
                color: random(),
              },
            },
          });
          for (let i = 0; i < titles.length; i++) {
            array.series[indexDate].data.push(
              Math.trunc((Math.random() * 300) / 0.5)
            );
          }
        });
        for (let i = 0; i < titles.length; i++) {
          array.xAxis[0].data.push(titles[i]);
        }
 
      });
         echartsList.data.forEach((val) => {
          renderChart(val.targetId, array);
        });
   
      proxy.$echarts.connect('group1') 

         
    };

    const random = () => {
      let r = Math.floor(Math.random() * 255);
      let g = Math.floor(Math.random() * 255);
      let b = Math.floor(Math.random() * 255);
      return `rgb(${r}, ${g}, ${b})`;
    };

    const changeType = (val, type) => {
      console.log(val)
      console.log(type)
      if (type == 1) {
        console.log(operation.value);
        console.log(comparison.data);
        if (comparison.data.findIndex((res) => res == operation.value) > -1) {
          proxy.$message.warning("运行日不能和对比日的日期相同！");
        } else {
          echartsList.data.forEach((item) => {
            console.log(item)
            item.date = val;
          });
        }
      } else if (type == 2) {
        echartsList.data.forEach((item) => {
            let tables = [];
            if(val){
          val.forEach((arr) => {
            tables.push({ date: arr, num: "112,662.674", percentage: "0%" });
          });
            }
           item.table = tables;
        });
 
      }
       echatsMap.options.series=[]
      chartFn();


    };

    const props = {
      label: "name",
      children: "children",
    };
  // 时间选择器禁止选择的日期 运行日
    const disabledDate=(time)=>{
           if(comparison.data&&comparison.data.length>0){
           let current=comparison.data.includes(proxy.$moment(time).format('YYYY-MM-DD'))
           return current
           }
    }
    

  // 时间选择器禁止选择的日期 对比日
  const disabledCon=(time)=>{
  return time.getTime() == proxy.$moment(operation.value).toDate().getTime()
   
    }

    onMounted(() => {
      nextTick(() => {
        chartFn();
   
      });
    });
    return {
      pointVal,
      pointList,
      operation,
      comparison,
      activeName,
      props,
      typeList,
      valList,
      tableList,
      tableRowClassName,
      echatsMap,
      renderChart,
      chartFn,
      echartsList,
      handleCheckChange,
      reback,
      changeType,
      random,
      disabledDate,
      disabledCon,
    
    };
  },
};
</script>

<style lang="scss" scoped>
.category {
  width: 260px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  --el-collapse-border-color: none;
  --el-collapse-content-bg-color: #090a1f;
  --el-collapse-header-bg-color: #022c63;
  --el-collapse-header-text-color: #ffffff;
  box-shadow: inset 0px 0px 10px 0px #3767ad;
  background: #090a1f;

  &:deep(.el-collapse-item__header) {
    padding-left: 20px;
    border-radius: 8px;
    width: 260px;
    box-sizing: border-box;
    // --el-collapse-header-bg-color:transparent
  }
  &:deep(.el-collapse-item__content) {
    height: 100%;
    overflow-y: auto;
    background: #0b183c;
    padding: 23px 0;
    box-shadow: inset 0px 0px 10px 0px #3767ad;
  }
  &:deep(.el-collapse-item__wrap) {
    box-shadow: inset 0px 0px 10px 0px #3767ad;
  }
  &:deep(.el-collapse-item:nth-of-type(2)) {
    .tree_collapse {
      max-height: 120px;
    }
  }
  &:deep(.el-collapse-item:last-child) {
    .el-collapse-item__wrap {
      border-radius: 0 0 8px 8px;
    }
    .tree_collapse {
      max-height: 150px;
    }
  }
}

.echarts_container {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  margin-left: 24px;
  max-height: 720px;

  .echarts_item {
    width: 100%;
    box-sizing: border-box;
    box-shadow: inset 0px 0px 10px 0px #3767ad;
    border-radius: 8px;
    // padding: 25px 0;
    margin-bottom: 22px;
    background: #0b183c;
    .echarts_box {
      display: flex;
      justify-content: space-between;

      .chart {
        align-items: stretch; // 拉伸
      }
    }

    .echarts_content {
      width: 950px;
      height: 290px;
      margin-bottom: 20px;
    }
    .day_details {
      width: 100%;
      // height: 100%;
      border-left: 1px solid #293657;
      display: flex;
      flex-direction: column;
      padding: 16px 15px;
      align-items: stretch; // 拉伸

      .day_title {
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-indent: 1em;
        margin: 20px 0;
        &::before {
          display: block;
          content: "";
          width: 10px;
          height: 10px;
          background: #12a8f0;
          border-radius: 50%;
        }
      }
      .date_day {
        width: 100%;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        text-indent: 2em;
        margin-bottom: 15px;
      }
      .date_day_none {
        width: 100%;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 15px;
      }

      &:deep(.el-radio-group) {
        width: 100%;
      }
      .table_date {
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
      .table_date::before {
        width: 8px;
        height: 8px;
        background: #432b5f;
        position: absolute;
        left: -18px;
        top: 6px;
        content: "";
        border-radius: 4px;
      }
    }

    .operating_title {
      margin: 16px 0;
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-indent: 1em;

      &::before {
        display: block;
        content: "";
        width: 4px;
        height: 25px;
        background: linear-gradient(135deg, #0081ff 0%, #22cce2 100%);
        border-radius: 0px 2px 2px 0px;
      }
    }
  }
}
// 滚动条
.echarts_container::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.echarts_container::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 4px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #aaacb1;
}
.echarts_container::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: #ffffff;
}

.operating_container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  .operating_item {
    width: 100%;
    margin-bottom: 24px;

    .operation {
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;

      &:deep(.el-input__wrapper) {
        background: #1f2935;
        box-shadow: none;
        margin-right: 32px;
      }
      &:deep(.el-input__inner) {
        color: #ffffff;
      }
    }
  }
  .crumbs {
    font-size: 14px;
    &:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner) {
      color: #ffffff;
    }
  }
  .main_container {
    // height: 30vh
    // height: 100%;
    // height: 100vh );
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
  }
}
.tree_collapse {
  width: 260px;
  box-sizing: border-box;
  background: transparent;
  padding: 0 15px;
  color: #ffffff;

  max-height: 160px;
  overflow-y: auto;

  &:deep(.el-tree-node__content:hover) {
    background-color: transparent;
  }
  &:deep(.el-tree-node:focus > .el-tree-node__content) {
    background-color: transparent;
  }
}

// 滚动条
.tree_collapse::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.tree_collapse::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 4px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #aaacb1;
}
.tree_collapse::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: #ffffff;
}
.table_day {
  overflow-y: auto;
  max-height: 200px;
  border-radius: 6px;
  color: #ffffff;
  --el-table-border: #262b37;
  border: none;
  background: #ffffff;

  &:deep(.el-table__body tr:hover > td) {
    background-color: transparent;
  }
  &:deep(.table_single) {
    --el-table-tr-bg-color: #212e3e;
  }
  &:deep(.table_double) {
    --el-table-tr-bg-color: #262b37;
    th {
      background: #022c63;
      color: #D6D1FC;
    }
  }
  &:deep(.el-table__inner-wrapper::before) {
    background-color: #262b37;
  }
}
</style>
